import React from "react";

import { Button } from "reactstrap";

import styled from "@emotion/styled";

const Style = styled.div`
padding: 5vh 0vw;

.button-cta {
    padding: 1ch 2ch;
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;
    height: 100% !important;

    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }
`

export default function CTA() {
    return (
        <Style>
            <h5 className="pain-content mx-2 my-0">
                In simple words: Bring your e-com biz to success!
            </h5>
            <div className="px-5 py-2">
                <Button
                    className="button-cta"
                    onClick={() =>
                        window.open("https://tidycal.com/stellure/strategy-call")
                    }
                >
                    <h2 className="m-0 px-5">START HERE</h2>
                </Button>
            </div>
        </Style>
    );
}
