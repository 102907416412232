import React from "react";

import PainText from "./PainText";
import PainCards from "./PainCards";
import PainText2 from "./PainText2";
import PleasureText from "./PleasureText";
import PleasureCards from "./PleasureCards";
import CTA from "./CTA";

import styled from "@emotion/styled";

const Style = styled.div`
padding: 5vh 10vw 10vh 10vw;
`

export default function PainToPleasure() {
  return (
    <Style>
      <PainText />
      <PainCards />
      <PainText2 />
      <PleasureText />
      <PleasureCards />
      <CTA />
    </Style>
  );
}
