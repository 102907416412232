import React from "react";

import styled from "@emotion/styled";

const Style = styled.div`
  padding: 5vh 0vw;

  .pain-heading {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;

    .underline {
      font-weight: 900 !important;
      background-image: linear-gradient(#f27405, #f27405);
      background-position: 0 1em;
      background-repeat: repeat-x;
      background-size: 18px 20%;
    }
  }

  .pain-content {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
  }
  .pain-subtext {
    font-family: "Open Sans", sans-serif;
    color: #5a5a5a;
  }

`;

export default function PainText() {
  return (
    <Style>
      <h2 className="pain-heading mx-2 my-3">
        Listen up,{" "}
        <strong className="underline">e-commerce biz owners!</strong>
      </h2>
      <h5 className="pain-content mx-2 my-0">
        We get it: You’re tired of feeling like a small star in this vast universe.

      </h5>
      <p className="pain-subtext mx-2 mb-3">
        (aka FB, IG, Tiktok, Lazada, & Shopee)
      </p>
    </Style>
  );
}
