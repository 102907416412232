import React from "react";

import styled from "@emotion/styled";

const Style = styled.div`
  padding: 5vh 0vw;

  .pain-heading {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;

    .underline {
      font-weight: 900 !important;
      background-image: linear-gradient(#f27405, #f27405);
      background-position: 0 1em;
      background-repeat: repeat-x;
      background-size: 18px 20%;
    }
  }

  .pain-content {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
  }
  .pain-subtext {
    font-family: "Open Sans", sans-serif;
    color: #5a5a5a;
  }

`;

export default function PainText2() {
  return (
    <Style>
      <h2 className="pain-heading mx-2 my-3">
        And worst? Spending your hard-earned money on ads with no results.
      </h2>
      <p className="pain-subtext mx-2 mb-3">
        I mean — how can this major facepalm moment be painful?
      </p>
    </Style>
  );
}
