export const heroParticles: any = {
  background: {
    color: {
      value: "",
    },
    image:
      "radial-gradient(at bottom right, rgba(242,116,5,1) 0%, rgba(5,131,242,1) 16.5%, rgba(40,60,111,1) 33%, rgba(0,0,0,1) 100%)",
    position: "",
    repeat: "",
    size: "cover",
    opacity: 1,
  },
  backgroundMask: {
    composite: "destination-out",
    cover: {
      color: {
        value: "#fff",
      },
      opacity: 1,
    },
    enable: false,
  },
  fullScreen: {
    enable: false,
    zIndex: -1,
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "grab",
      },
      resize: true,
    },
    modes: {
      push: {
        quantity: 2,
      },
      grab: {
        distance: 200,
      },
    },
  },
  particles: {
    color: {
      value: "#ffffff",
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 0.25,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 400,
      },
      value: 20,
    },
    opacity: {
      value: { min: 0.5, max: 1 },
    },
    shape: {
      type: ["char"],
      options: {
        char: {
          value: "⟡",
        },
      },
    },
    size: {
      value: { min: 5, max: 10 },
    },
  },
  detectRetina: true,
};

export const portfolioParticles: any = {
  background: {
    color: {
      value: "",
    },
    image:
      "radial-gradient(at bottom,rgba(40, 60, 111, 1) 25%,rgba(0, 0, 0, 1) 100%)",
    position: "",
    repeat: "",
    size: "cover",
    opacity: 1,
  },
  backgroundMask: {
    composite: "destination-out",
    cover: {
      color: {
        value: "#fff",
      },
      opacity: 1,
    },
    enable: false,
  },
  fullScreen: {
    enable: false,
    zIndex: -1,
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "grab",
      },
      resize: true,
    },
    modes: {
      push: {
        quantity: 2,
      },
      grab: {
        distance: 200,
      },
    },
  },
  particles: {
    color: {
      value: "#ffffff",
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 0.25,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 400,
      },
      value: 20,
    },
    opacity: {
      value: { min: 0.5, max: 1 },
    },
    shape: {
      type: ["char"],
      options: {
        char: {
          value: "⟡",
        },
      },
    },
    size: {
      value: { min: 5, max: 10 },
    },
  },
  detectRetina: true,
};
