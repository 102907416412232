import React from "react";

import Hero from "./Hero";
import PainToPleasure from "./PainToPleasure/PainToPleasure"
import Services from "./Services";
import ShineMethod from "./ShineMethod/ShineMethod";
import About from "./About";
import Portfolio from "./Portfolio";
import Closing from "./Closing";

import styled from "@emotion/styled";

const Style = styled.div`
  margin: 0;
  padding: 0;

  .horizontal-rule {
    padding: 0 10vw 10vh 10vw;
  }
`;

export default function Home() {
  return (
    <Style>
      <Hero />
      <PainToPleasure />
      <Services />
      <ShineMethod />
      <About />
      <Portfolio />
      <Closing />
    </Style>
  );
}
