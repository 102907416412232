import React, { useState, useEffect } from "react";

import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";

import styled from "@emotion/styled";

import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";

import StellureDigitalLogo from "../../Assets/Images/stellure_digital_logo.svg";

const Style = styled.div`
  margin: 0;
  background-color: #000;

  .navlink {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    color: #f7fbff !important;
  }

  .navlink-icon {
    cursor: pointer;
    color: #f7fbff;
  }

  .button-cta {
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;

    font-family: "Oswald", sans-serif;
  }
`;

export default function Footer() {
  const [year, setYear] = useState(2022)

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])

  return (
    <Style>
      <div>
        <Navbar className="px-5" dark>
          <NavbarBrand href="/">
            <img
              src={StellureDigitalLogo}
              alt="stellure digital logo"
              height="75"
            />
          </NavbarBrand>
          <Nav className="d-inline-flex me-auto" navbar>
            <NavItem>
              <NavLink
                className="navlink"
                onClick={() =>
                  window.open("https://www.facebook.com/stelluredigital/")
                }
              >
                <FaFacebookF className="navlink-icon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="navlink"
                onClick={() =>
                  window.open("https://www.instagram.com/stelluredigital_/")
                }
              >
                <FaInstagram className="navlink-icon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="navlink"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/merylgracesantiago/")
                }
              >
                <FaLinkedinIn className="navlink-icon" />
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
      <p className="navlink">© All rights reserved. Stellure Digital {year}.</p>
    </Style>
  );
}
