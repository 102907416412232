import React from "react";

import ShineText from "./ShineText";
import Process from "./Process";
import CTA from "./CTA";

import styled from "@emotion/styled";

const Style = styled.div`
padding: 5vh 10vw 10vh 10vw;
`

export default function ShineMethod() {
  return (
    <Style>
      <ShineText />
      <Process />
      <CTA />
    </Style>
  );
}
