import React from "react";
import "./App.css";

import Appbar from "./Components/Appbar/Appbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Appbar />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
