import React from "react";

import styled from "@emotion/styled";

const Style = styled.div`

  .card-container {
    flex-wrap: wrap;
  }
  .card-item {
    flex: 0 1 19.75vw;

    position: relative;
    cursor: pointer;
  }

  .card-item:hover .face.face-1 {
    border-radius: 15px;
    background-color: #f27405;
    opacity: 1;
    box-shadow: 0px 2px 10px black;
    border-radius: 15px;
    z-index: 10;
    transform: scale(1.25);
    height: 100%;
    display: block;
  }
  .card-item:hover .face.face-1:after{
    display: none;
  }
  .card-item:hover .face.face-1 .pain-content {
    color: #f7fbff;
  }

  .face {
    padding: 2vh 2vw;
    transition: 0.5s;
  }

  .face.face-1 {
    background-color: #283c6f;
    opacity: 1;
    position: relative;
    z-index: 2;
    box-shadow: 0px 2px 10px -10px black;
    height: 300px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical; 
  }
  .face.face-1:after{
    content: ' ';
    display: block;
    background-color: inherit;
    height: 2.5vh;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .face.face-1.face-start {
    border-radius: 15px 0px 0px 15px;
  }
  .face.face-1.face-mid {
    border-radius: 0px;
  }
  .face.face-1.face-end {
    border-radius: 0px 15px 15px 0px;
  }

  .pain-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #f7fbff;

    text-align: left;
  }
  .pain-subtext {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

  @media only screen and (max-width: 768px) {
    .card-item {
      flex: 0 1 79vw;

      margin: 1vh 0;
    }
    .face.face-1.face-start, .face.face-1.face-mid, .face.face-1.face-end {
      border-radius: 15px;
    }
  }
`;

export default function PleasureCards() {
  return (
    <Style>
      <div className="d-flex justify-content-center">
        <div className="card-container d-flex justify-content-center">
          <div className="card-item">
            <div className="face face-1 face-start">
              <h4 className="pain-content pt-3">
                <strong>Dominate your biz </strong>platforms like a boss — getting your ad game on point, measuring your ROI like a pro, and hitting your target audience right where it counts.
              </h4>
            </div>
          </div>
          <div className="card-item">
            <div className="face face-1 face-mid">
              <h4 className="pain-content pt-3">
                Finally take your business to the next level of satisfaction as you <strong>watch your sales grow </strong>while ensuring your <strong>accounts are safe and sound. </strong>You love that, don’t you?
              </h4>
            </div>
          </div>
          <div className="card-item">
            <div className="face face-1 face-mid">
              <h4 className="pain-content pt-3">
                Transform your business with a <strong>solid plan of action</strong> and <strong>sustainable marketing solutions</strong> to set you apart from the competition and, eventually, increase your revenue.
              </h4>
            </div>
          </div>
          <div className="card-item">
            <div className="face face-1 face-end">
              <h4 className="pain-content pt-3">
                Collaborate with a team who are passionate about what we do. <strong>Driving real results. Driving sales and growth.</strong> Because hey! It takes two (or even more) to make your biz tango.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}
