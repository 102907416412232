import React, { useEffect, useState } from "react";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import styled from "@emotion/styled";

import MorenaTheLabel from "../../Assets/Videos/MorenaTheLabel.mp4";
import StyleBound from "../../Assets/Videos/StyleBound.mp4";
import Silaw from "../../Assets/Videos/Silaw.mp4";

import { portfolioParticles } from "../Config/tsParticles";

const Style = styled.div`
  .content-container {
    position: relative;
  }

  .portfolio-title {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
    font-weight: 900;
  }

  .portfolio-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .portfolio-child {
    border: 5px solid #f7fbff;
    border-radius: 25px;

    flex: 0 1 400px;
    color: #f7fbff;
  }

  .video-player {
    border-radius: 15px 15px 0 0;
    aspect-ratio: 18 / 10;
  }

  .portfolio-description {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

  .portfolio-subtext {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

  .button-cta {
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;

    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

  .tsparticle-container {
    width: 100%;
    position: absolute;
    left: 0;
  }

  box-shadow: 0px 2px 10px black;
`;

export default function Portfolio() {
  // const [portfolioHeight, setPortfolioHeight] = useState("100%");

  const particlesInit = async (main: any) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      let portfolioHeight = `${entries[0].target.clientHeight}px`;
      document.getElementById("portfolio")!.style.height = portfolioHeight;
    });

    resizeObserver.observe(document.getElementById("portfolio-container")!);
    return () => {
      resizeObserver.unobserve(document.getElementById("portfolio-container")!);
    };
  }, []);

  return (
    <Style id="portfolio-container" className="m-0 p-0">
      <Particles
        id="portfolio"
        className="tsparticle-container"
        init={particlesInit}
        options={portfolioParticles}
      />
      <div className="content-container py-5 mb-5">
        <div className="py-5">
          <h1 className="portfolio-title">Hear From Our Happy Clients</h1>
          <div className="portfolio-parent my-5">
            <div className="portfolio-child m-2 pb-5">
              <video className="video-player" width="100%" controls>
                <source src={Silaw} type="video/mp4" />
              </video>
              <h3 className="portfolio-title">Jody & Jacqui Dizon</h3>
              <h5 className="portfolio-description">Silaw Jewelry</h5>
            </div>
            <div className="portfolio-child m-2 pb-5">
              <video className="video-player" width="100%" controls>
                <source src={MorenaTheLabel} type="video/mp4" />
              </video>
              <h3 className="portfolio-title">Ayn Bernos</h3>
              <h5 className="portfolio-description">Morena The Label</h5>
            </div>
            <div className="portfolio-child m-2 pb-5 ">
              <video className="video-player" width="100%" controls>
                <source src={StyleBound} type="video/mp4" />
              </video>
              <h3 className="portfolio-title">Rica Abrazado</h3>
              <h5 className="portfolio-description">Style Bound Clothing</h5>
            </div>
          </div>
          <div className="py-5">
            <h1 className="portfolio-title">And more love letters</h1>
            <br />
            <br />
            <h5 className="portfolio-description">“We are building a brand that’ll be marked on everyone’s heart,
              and I am so glad that I’ve found you &lt;3 &lt;3”
            </h5>
            <br />
            <p className="portfolio-subtext mx-2 mb-3">
              Alyssa M. <br /> <i>Business Owner</i>
            </p>
            <br />
            <hr></hr>
            <br />
            <p className="portfolio-subtext mx-2 mb-3">
              “We like the result-driven approach of your team. Your firm grasp on the technology and your ability to adapt and adjust depending on the situation, environment, or strategy of the client.
            </p>
            <br />
            <h5 className="portfolio-description">
              We are happy with your service Meryl, no doubt.
              Your data is one of the keys to our growth.”
            </h5>
            <br />
            <p className="portfolio-subtext mx-2 mb-3">
              F. O. <br /> <i>Business Owner</i>
            </p>
          </div>
          {/* <div className="py-5">
            <Button
              className="px-5 button-cta"
              onClick={() =>
                window.open(
                  "https://tidycal.com/stellure/strategy-call"
                )
              }
            >
              <h3 className="m-0">CONTACT US</h3>
            </Button>
          </div> */}
        </div>
      </div>
    </Style>
  );
}
