import React from "react";

import { Button } from "reactstrap";

import styled from "@emotion/styled";

const Style = styled.div`
  .timeline {
    position: relative;
    list-style: none;
    padding: 1rem 0;
    margin: 0;
  }

  .timeline::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-left: -1px;
    background-color: #0583f2;
  }
  .timeline-item {
    position: relative;
    width: 50%;
    padding: 1rem 0;
    padding-right: 2.5rem;
    text-align: right;
  }

  .timeline-item::before {
    content: "";
    position: absolute;
    right: -8px;
    top: 1.35rem;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #0583f2;
    background-color: #fff;
  }
  .timeline-item:nth-child(even) {
    margin-left: 50%;
    padding-left: 2.5rem;
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(even)::before {
    right: auto;
    left: -8px;
  }
  .timeline-horizontal {
    display: flex;
  }
  .timeline-horizontal::before {
    left: 0;
    bottom: auto;
    right: 0;
    width: 100%;
    height: 2px;
  }
  .timeline-horizontal .timeline-item {
    padding: 1.5rem 1rem;
    text-align: left;
  }
  .timeline-horizontal .timeline-item::before {
    top: -1.5rem;
    left: 0.75rem;
  }
  .timeline-horizontal .timeline-item:nth-child(even) {
    margin-left: 0;
  }
  @media (max-width: 767.98px) {
    .timeline::before {
      left: 8px;
    }
    .timeline-item {
      width: 100%;
      text-align: left;
      padding-left: 2.5rem;
      padding-right: 0;
    }
    .timeline-item::before {
      top: 1.25rem;
      left: 1px;
    }
    .timeline-item:nth-child(even) {
      margin-left: 0rem;
    }
    .timeline-item:nth-child(even)::before {
      left: 1px;
    }
    .timeline-horizontal {
      flex-direction: column;
    }
    .timeline-horizontal::before {
      right: auto;
      bottom: 0;
      width: 2px;
      height: 100%;
    }
    .timeline-horizontal .timeline-item {
      padding: 1rem 0;
      padding-left: 2.5rem;
    }
    .timeline-horizontal .timeline-item::before {
      top: 1.25rem;
      left: 1px;
    }
  }

  .process-title {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;
  }
  .process-header {
    font-family: "Open Sans", sans-serif;
    color: #f27405;
    font-weight: 900;
  }
  .process-description {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
  }
  .drop-cap {
    font-size: 2em;
  }

  .button-cta {
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;

    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }
`;

export default function Process() {
  return (
    <Style>
      <section className="py-5 my-5 section">
        <div className="container">

          <div className="row">
            <div className="col-lg mx-auto">
              <ol className="timeline">
                <li className="timeline-item">
                  <h3 className="process-header fw-700"><strong className="drop-cap">S</strong>urveying the ads</h3>
                  <h5 className="process-description">
                    If Facebook, Instagram, and others protect their users from poor and harmful experiences caused by ads, then we are here to keep you from making these mistakes.
                    <br />
                    <br />
                    In this phase…
                    <br />
                    <br />
                    We will check your ad accounts and pages.
                    We will assess their status and quality.
                    We will continually track their conditions.
                    <br />
                    <br />
                    Our main goal? Ensure your account is safe, with precise data, and follows regulations — decreasing the chances of your account or page getting restricted or deactivated.
                  </h5>
                </li>

                <li className="timeline-item">
                  <h3 className="process-header fw-700"><strong className="drop-cap">H</strong>itting the books on the market</h3>
                  <h5 className="process-description">
                    We’re not creating and managing ads just to get them out there, then expecting sales from them.
                    <br />
                    <br />
                    No. It’s not like that!
                    <br />
                    <br />
                    You can only make sales by targeting the right people.
                    You can only make sales by doing in-depth research.
                    <br />
                    <br />
                    And with your never-ending to-do list? No worries!
                    We are here to save you from the overwhelm and stress.
                    <br />
                    <br />
                    This is the phase where we’ll identify your audience's needs, desires, difficulties, and inspirations. Where to find them? How to find them?
                    <br />
                    <br />
                    These data will help us position your ads to make your audience click your Call-To-Action button…
                    <br />
                    <br />
                    And eventually, add $$$$ to your bank.
                    your goals.
                  </h5>
                </li>

                <li className="timeline-item">
                  <h3 className="process-header fw-700"><strong className="drop-cap">I</strong>mplementing a customer-centric marketing funnel</h3>
                  <h5 className="process-description">
                    A customer-centric, full-funnel system focusing on acquiring and retargeting your customers.
                    <br />
                    <br />
                    In simple terms: A step-by-step customer journey from the moment an audience discovers your brand to buying your product (and buying for more).
                    <br />
                    <br />
                    What’s with this system? Proven. Profitable. Scalable.
                    A system that will help us get the possible results from our advertising strategies.
                    <br />
                    <br />
                    In this phase, we’ll CREATE and IMPLEMENT this funnel for you.
                    <br />
                    <br />
                    That’s what you invested. And we don’t waste your investment.
                  </h5>
                </li>

                <li className="timeline-item">
                  <h3 className="process-header fw-700"><strong className="drop-cap">N</strong>ailing the creative and copy</h3>
                  <h5 className="process-description">
                    Entering… the making of your brand’s Ad copy and creatives. ✨
                    <br />
                    <br />
                    But No, No, No! We don’t simply write a copy and create your video ads, photos, carousels, reels, and user-generated content in one go.
                    <br />
                    <br />
                    What do we do?
                    <br />
                    <br />
                    We produce a solid set of creatives we’ll use to TEST different ways of attracting your clients.
                    <br />
                    <br />
                    We always test, test, and test again to understand what your audience wants to see, read, and compel them to take action.
                    <br />
                    <br />
                    That is how we make your ad creatives and copy stand out.
                    That’s how you NAIL your ads.
                  </h5>
                </li>

                <li className="timeline-item">
                  <h3 className="process-header fw-700"><strong className="drop-cap">E</strong>valuating performance</h3>
                  <h5 className="process-description">
                    Running away after creating and implementing your ads is outside our vocabulary.
                    <br />
                    <br />
                    We also evaluate your performance!
                    <br />
                    <br />
                    We get down and dirty with our key performance indicators (KPIs) and analyze all our ad performances — from reach to impressions to results — always optimizing our efforts to achieve the best possible results for your business.
                    <br />
                    <br />
                    And oh! We love getting up close and personal with our clients. Lack of communication who? We have monthly online meetings where we sit down and review our performance.
                    <br />
                    <br />
                    Numbers? Consultation? Sales? We got you covered!
                  </h5>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </Style>
  );
}
