import React from "react";

import styled from "@emotion/styled";

import { AiOutlineFacebook } from "@react-icons/all-files/ai/AiOutlineFacebook";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { IoLogoTiktok } from "@react-icons/all-files/io5/IoLogoTiktok";
import { IoStorefrontOutline } from "@react-icons/all-files/io5/IoStorefrontOutline"
import { ReactComponent as Lazada } from "../../Assets/Images/SVG/lazada.svg";
import { ReactComponent as Shopee } from "../../Assets/Images/SVG/shopee.svg";

const Style = styled.div`
  .services-main-title {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;
  }

  .services-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .services-child {
    padding-top: 2rem;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 250px;
    color: #fbfaf7;
    transition: all 0.3s ease-out;
    cursor: pointer;

    min-height: 400px;

    box-shadow: 0 0 12px rgb(0 0 0 / 6%);
  }

  .services-child:hover {
    flex: 0 1 500px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .services-child:hover .services-description {
    display: block;
  }

  .services-icon {
    color: #fbfaf7;
  }

  .services-title {
    font-family: "Open Sans", sans-serif;
    color: #fbfaf7;
  }

  .services-description {
    font-family: "Open Sans", sans-serif;
    color: #fbfaf7;
    text-align: left;

    display: none;
  }
`;

export default function Services() {
  return (
    <Style>
      <div className="p-5 my-5">
        <h1 className="services-main-title">Main Services</h1>
        <div className="services-parent my-5">
          <div className="services-child" style={{
            backgroundColor: "#283c6f"
          }}>
            <AiOutlineFacebook className="services-icon display-4" />

            <h5 className="p-3 services-title">Facebook Ads</h5>

            <p className="p-3 services-description">2.963 billion.
              <br />
              <br />
              That’s the number of monthly active users of Facebook in the world.  Imagine reaching a percent or more of these people through our comprehensive and strategic Facebook advertising.
              <br />
              <br />
              Our holistic approach to Facebook ads will generate interest, motivate your audience to consider your brand, and encourage them to buy your products.
              <br />
              <br />
              Crowded online space who?
              Crazy Facebook guidelines who?
              <br />
              <br />
              We’re promoting your fashion and health & wellness e-commerce business to the most active social media platform. So buckle up and get ready for the ride.
            </p>
          </div>
          <div className="services-child" style={{
            backgroundColor: "#2c4c8e"
          }}>
            <FaInstagram className="services-icon display-4" />

            <h5 className="p-3 services-title">Instagram Ads</h5>

            <p className="p-3 services-description">The right platform to reach a highly-targeted audience: Instagram
              <br />
              <br />
              A platform where you can use your photos, stories, and videos to promote your business like no other.
              <br />
              <br />
              And when you partner with Stellure digital, your Instagram ads will make your business easier to discover, boost your brand’s following, communicate with your followers smoothly…
              <br />
              <br />
              And most of all, effective enough to compel your audience to take action. This is what we call REAL results!
            </p>
          </div>
          <div className="services-child" style={{
            backgroundColor: "#2b5eae"
          }}>
            <IoLogoTiktok className="services-icon display-4" />

            <h5 className="p-3 services-title">Tiktok Ads</h5>

            <p className="p-3 services-description">TikTok is not anymore just a ‘happy dancing’ and ‘lip syncing’ platform.
              <br />
              <br />
              Businesses are now taking advantage of how this video channel can promote brand awareness, drive online sales, and build a great online following.
              <br />
              <br />
              With the huge audience TikTok has, advertising is a smart move to skyrocket your e-commerce game. And that’s what we’re here for.
              <br />
              <br />
              We’re making your TikTok ads as eye-catching and thumb-stopping as you want.
              <br />
              <br />
              Just a little reminder though: Make sure your products can handle the demand. 😉
            </p>
          </div>
          <div className="services-child" style={{
            backgroundColor: "#2470cf"
          }}>
            <Lazada className="services-icon display-4" />

            <h5 className="p-3 services-title">Lazada Ads</h5>

            <p className="p-3 services-description">Bring your products in front of the online selling platform you’re using (aka Lazada advertising).
              <br />
              <br />
              Who knows? Your Lazada ads can be your secret sauce to boosting your e-com business. Scaling and growing your online store.
              <br />
              <br />
              And Stellure digital is at your service. We’ll strategize with you to increase your brand awareness and store visibility — get higher visits, more product orders, and more sales.
              <br />
              <br />
              We know how much you want this for your biz.
              We want this for you too. 💖
            </p>
          </div>
          <div className="services-child" style={{
            backgroundColor: "#0583f2"
          }}>
            <Shopee className="services-icon display-4" />

            <h5 className="p-3 services-title">Shopee Ads</h5>

            <p className="p-3 services-description">You’re an online seller who deserves to showcase your shop to potential customers who want to purchase your products.
              <br />
              <br />
              Your answer: Shopee advertising to boost high traffic, visibility, and chances of discovery.
              <br />
              <br />
              We are here to take your Shopee ads to buyers with high purchase intent and be seen by the right people. The more people our ads can reach, the higher the chance of interest. The better your strategies are in advertising, the higher the chance of converting them.
              <br />
              <br />
              With us, you’ll be able to boost the exposure of your products and increase your sales with Shopee Ads.
            </p>
          </div>
        </div>
      </div>
    </Style>
  );
}
