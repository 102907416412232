import React from "react";

import { Button } from "reactstrap";

import styled from "@emotion/styled";

const Style = styled.div`
  padding: 5vh 10vw 10vh 10vw;

//   text-align: left;

  .closing-heading {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;

    .underline {
      font-weight: 900 !important;
      background-image: linear-gradient(#f27405, #f27405);
      background-position: 0 1em;
      background-repeat: repeat-x;
      background-size: 18px 20%;
    }
  }

  .closing-content {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
  }
  .closing-subtext {
    font-family: "Open Sans", sans-serif;
    color: #5a5a5a;
  }

  .button-cta {
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;

    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

`;

export default function Closing() {
  return (
    <Style>
      <h5 className="closing-content mx-2 my-0">
        You have two choices.
      </h5>
      <br />
      <h5 className="closing-content mx-2 my-0">
        You can stay as an e-commerce business owner who drives sales.
        <br />Or

      </h5>
      <h2 className="closing-heading mx-2 my-3">
        You can be an e-commerce brand owner <br />
        who stands out and drives MORE sales.
      </h2>
      <p className="about-subtext mx-2 mb-3">
        Without the overwhelm. Without the stress. <br />
        Without the fear of wasting your investment.
      </p>
      <br />
      <h2 className="closing-heading mx-2 my-3">
        Ready to <i>holistically</i> advertise your products? <br />
        Ready to skyrocket your biz?
      </h2>
      <br />
      <div className="py-5">
        <Button
          className="px-5 button-cta"
          onClick={() =>
            window.open(
              "https://tidycal.com/stellure/strategy-call"
            )
          }
        >
          <h3 className="m-0">SUPER READY!!!</h3>
        </Button>
      </div>
    </Style >
  );
}
