import React, { useState, useEffect } from "react";

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
  Button,
} from "reactstrap";

import styled from "@emotion/styled";

import StellureDigitalLogo from "../../Assets/Images/stellure_digital_logo.svg";

const Style = styled.div`
  .default-transparent {
    background-color: rgba(52, 65, 120, 0);
  }
  .default-opaque {
    background-color: rgba(52, 65, 120, 0.8);
    box-shadow: 0px 2px 10px black;
  }

  .navlink {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff !important;
  }

  .button-cta {
    padding: 0.5ch 2ch;
    background-color: #f27405;
    border-radius: 25px;
    border-style: none;
    height: 100% !important;

    font-family: "Open Sans", sans-serif;
  }
`;

export default function Appbar() {
  const [bg, setBg] = useState("default-transparent");
  const [toggle, setToggle] = useState(false);

  const handleScroll = () => {
    const show = window.scrollY > 0;
    if (show) {
      setBg("default-opaque");
    }
    if (!show) {
      setBg("default-transparent");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Style>
      <div className={`appbar ${bg} px-5`}>
        <Navbar className={`appbar ${bg} px-5`} expand="md" fixed="top" dark>
          <NavbarBrand href="/">
            <img
              src={StellureDigitalLogo}
              alt="stellure digital logo"
              height="75"
            />
          </NavbarBrand>
          <NavbarToggler onClick={() => setToggle(!toggle)} />
          <Collapse isOpen={toggle} navbar>
            <Nav className="ms-auto" navbar>
              <NavItem>
                <NavLink className="navlink" href="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="button-cta"
                  onClick={() =>
                    window.open("https://tidycal.com/stellure/strategy-call")
                  }
                >
                  <h6 className="m-1">Contact Us</h6>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </Style>
  );
}
