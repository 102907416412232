import React from "react";

import styled from "@emotion/styled";

const Style = styled.div`
  padding: 5vh 10vw 10vh 10vw;

//   text-align: left;

  .about-heading {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
    font-weight: 900;

    .underline {
      font-weight: 900 !important;
      background-image: linear-gradient(#f27405, #f27405);
      background-position: 0 1em;
      background-repeat: repeat-x;
      background-size: 18px 20%;
    }
  }

  .about-content {
    font-family: "Open Sans", sans-serif;
    color: #283c6f;
  }
  .about-subtext {
    font-family: "Open Sans", sans-serif;
    color: #5a5a5a;
  }

`;

export default function About() {
    return (
        <Style>
            <hr></hr>
            <h5 className="about-content mx-2 my-0">
                Hey hey!

            </h5>
            <h2 className="about-heading mx-2 my-3">
                I’m Meryl!
            </h2>

            <p className="about-subtext mx-2 mb-3">
                (the owner of Stellure Digital)
            </p>
            <br />
            <h5 className="about-content mx-2 my-0">
                Let me introduce myself with this word: ✨EXTRA✨
            </h5>
            <br />
            <h5 className="about-content mx-2 my-0">
                I am <i>extra</i> hard-working.
            </h5>
            <p className="about-subtext mx-2 mb-3">
                I used to work soooo hard at an 8-5 job, thinking it was all I ever needed. After almost 3 years of corporate
                perseverance, I finally said, “TAMA NA!” — a Filipino word that means, “I’M DONE!”.
            </p>
            <br />
            <h5 className="about-content mx-2 my-0">
                I have <i>extra</i>ordinary dreams. Digital nomad dreams.
            </h5>
            <p className="about-subtext mx-2 mb-3">
                Own a business. Impact the lives of others. Establish my online store. <i>And that’s how everything started.</i>
            </p>
            <br />
            <h5 className="about-content mx-2 my-0">
                I became <i>extra</i>.
            </h5>
            <p className="about-subtext mx-2 mb-3">
                I took the plunge and established my own online store. And the thrill of the game? The excitement of watching my sales grow?
            </p>
            <br />
            <h5 className="about-content mx-2 my-0">
                It was all too much to resist that <strong className="underline">it made my fascination with e-commerce grow.</strong>
            </h5>
            <p className="about-subtext mx-2 mb-3">
                And even if I had to put my dreams on hold to focus on finishing my studies, I still found myself managing ad campaigns for other E-commerce businesses. I saw firsthand how a killer ad campaign could make all the difference in skyrocketing sales.
            </p>
            <br />
            <h5 className="about-content mx-2 my-0">
                It was then that I knew I had found my purpose — <i>to help more e-commerce businesses skyrocket their sales for their hopes and their dreams…</i>
            </h5>
            <br />
            <h5 className="about-content mx-2 my-0">
                And SHINE as one of the brightest stars in the online universe.
            </h5>
            <br />
            <h5 className="about-content mx-2 my-0">
                That. is. extra.
            </h5>
            <hr></hr>
        </Style >
    );
}
