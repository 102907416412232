import React from "react";

import styled from "@emotion/styled";

import Struggling from "../../../Assets/Images/SVG/struggling.svg"
import Trying from "../../../Assets/Images/SVG/trying.svg"
import Hoping from "../../../Assets/Images/SVG/hoping.svg"

const Style = styled.div`

  .card-container {
    flex-wrap: wrap;
  }
  .card-item {
    flex: 0 1 26.33vw;

    position: relative;
    cursor: pointer;
  }

  .card-item:hover .face.face-1 {
    border-radius: 15px;
    background-color: #f27405;
    opacity: 1;
    box-shadow: 0px 2px 10px black;
    border-radius: 15px 15px 0 0;
    transform: translateY(0);
    position: relative;
    z-index: 1;
  }
  .card-item:hover .face.face-1 .pain-image {
    opacity: 1;
  }
  .card-item:hover .face.face-1 .pain-content {
    opacity: 1;
  }
  .card-item:hover .face.face-2 {
    box-shadow: 0px 2px 10px black;
    border-radius: 0 0 15px 15px;
    transform: translateY(0);
  }

  .face {
    padding: 2vh 7vw;
    transition: 0.5s;
  }

  .face.face-1 {
    background-color: #283c6f;
    opacity: 1;
    position: relative;
    z-index: 2;
    transform: translateY(100px);
    box-shadow: 0px 2px 10px -10px black;
  }
  .face.face-1.face-start {
    border-radius: 15px 0px 0px 15px;
  }
  .face.face-1.face-mid {
    border-radius: 0px;
  }
  .face.face-1.face-end {
    border-radius: 0px 15px 15px 0px;
  }

  .face.face-2 {
    opacity: 1;
    border-radius: 15px;
    position: relative;
    background-color: #f7fbff;
    height: 150px;
    transform: translateY(-150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .face.face-2:hover {
    transform: translateY(0);
  }

  .pain-image {
    opacity: .6
  }

  .pain-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #f7fbff;
    opacity: .6
  }
  .pain-subtext {
    font-family: "Open Sans", sans-serif;
    color: #f7fbff;
  }

  @media only screen and (max-width: 768px) {
    .card-item {
      flex: 0 1 79vw;
    }
    .face.face-1.face-start, .face.face-1.face-mid, .face.face-1.face-end {
      border-radius: 15px;
    }
  }
`;

export default function PainCards() {
  return (
    <Style>
      <div className="d-flex justify-content-center">
        <div className="card-container d-flex justify-content-center">
          <div className="card-item">
            <div className="face face-1 face-start">
              <img className="pain-image" src={Struggling} width="200px" />
              <h4 className="pain-content pt-3">
                Struggling
              </h4>
            </div>
            <div className="face face-2 px-5">
              <h4>
                Struggling to attract new customers.
              </h4>
            </div>
          </div>
          <div className="card-item">
            <div className="face face-1 face-mid">
              <img className="pain-image" src={Trying} width="200px" />
              <h4 className="pain-content pt-3">
                Trying
              </h4>
            </div>
            <div className="face face-2 px-5">
              <h4>
                Trying hard to increase more more sales.
              </h4>
            </div>
          </div>
          <div className="card-item">
            <div className="face face-1 face-end">
              <img className="pain-image" src={Hoping} width="200px" />
              <h4 className="pain-content pt-3">
                Hoping
              </h4>
            </div>
            <div className="face face-2 px-5">
              <h4>
                Hoping for old marketing strategies to finally work.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}
